/*
    ----------
*/
$Font : (
    "Default" : "Inter"
);
/* 
    ----------
*/
$Size : (
    "10" : 10px,
    "11" : 11px,
    "12" : 12px,
    "13" : 13px,
    "14" : 14px,
    "15" : 15px,
    "16" : 16px,
    "17" : 17px,
    "18" : 18px,
    "19" : 19px,
    "20" : 20px
);
/* 
    ---------- 
*/
$Color : (
    "Default" : #EA1917,
    "White"   : #FFFFFF,
    "Black"   : #333333,
    "Dark"    : #000000,
    "Grey"    : #F0ECE4,
    "Light"   : #E6E1D6
);
/* 
    ----------
*/
.dE5574{ font-family: map-get( $Font, "Default" ) }
/* 
    ----------
*/
.S6pt64{ float: left  }
.yJ452A{ float: right }
.eTt257{ float: none  }
/* 
    ----------
*/
.q7h2M9{ width: 25%  }
.S09r6B{ width: 50%  }
.b7P443{ width: 75%  }
.C96Q4r{ width: 100% }
/* 
    ----------
*/
.eU7H63{ font-size: map-get( $Size, "10" ) }
.S7gw68{ font-size: map-get( $Size, "11" ) }
.iI0402{ font-size: map-get( $Size, "12" ) }
.xG0j09{ font-size: map-get( $Size, "13" ) }
.PlO170{ font-size: map-get( $Size, "14" ) }
.wI860z{ font-size: map-get( $Size, "15" ) }
.Wm8G06{ font-size: map-get( $Size, "16" ) }
.bS8S40{ font-size: map-get( $Size, "17" ) }
.s4U1j1{ font-size: map-get( $Size, "18" ) }
.J589Ta{ font-size: map-get( $Size, "19" ) }
.i260Up{ font-size: map-get( $Size, "20" ) }
/* 
    ----------
*/
.C2y88Q{ font-weight: lighter }
.u37E41{ font-weight: normal  }
.Y4674t{ font-weight: bold    }
.zK3920{ font-weight: bolder  }
/* 
    ----------
*/
.F2n16X{ font-weight: 100 }
.Y8k86e{ font-weight: 200 }
.y3Or88{ font-weight: 300 }
.nS533w{ font-weight: 400 }
.K0cW96{ font-weight: 500 }
.D933nr{ font-weight: 600 }
.jS8601{ font-weight: 700 }
.Zv29i2{ font-weight: 800 }
.Is1259{ font-weight: 900 }
/* 
    ----------
*/
.J284Xb{ font-style: normal  }
.Ep1U39{ font-style: oblique }
.R6t03r{ font-style: italic  }
/* 
    ----------
*/
.v6EU83{ color: map-get( $Color, "Default" ) }
.M99v3q{ color: map-get( $Color, "White" )   }
.vOx602{ color: map-get( $Color, "Black" )   }
.Y0u334{ color: map-get( $Color, "Grey" )    }
.r4K8f4{ color: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.cvC365{ fill: map-get( $Color, "Default" ) }
.f8T6I8{ fill: map-get( $Color, "White" )   }
.sRU313{ fill: map-get( $Color, "Black" )   }
.Vr4196{ fill: map-get( $Color, "Grey" )    }
.b29YY3{ fill: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.u0Bc59{ background: map-get( $Color, "Default" ) }
.rP408N{ background: map-get( $Color, "White" )   }
.u3I753{ background: map-get( $Color, "Black" )   }
.i3eE58{ background: map-get( $Color, "Grey" )    }
.gZ959C{ background: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.wDC543{ display: inline-block }
.eF429z{ display: inline-flex  }
.xA723K{ display: table-cell   }
.X35k4K{ display: table-row    }
.bN2p30{ display: inline       }
.S0u16d{ display: flex         }
.V81zg1{ display: block        }
.oi8E43{ display: grid         }
.zD661i{ display: table        }
.t3c0N7{ display: -webkit-box  }
.hPP928{ display: none         }
.s6D202{ display: list-item    }
/* 
    ----------
*/
.fE0d48{ flex-direction: column         }
.L640Ti{ flex-direction: column-reverse }
.Y065Fd{ flex-direction: row-reverse    }
.fr598R{ flex-direction: row            }
/* 
    ----------
*/
.ZH339f{ justify-content: flex-start    }
.U080Kf{ justify-content: flex-end      }
.vR05U6{ justify-content: center        }
.g1aS48{ justify-content: space-between }
.h18Ea4{ justify-content: space-around  }
.aR51Y0{ justify-content: space-evenly  }
/* 
    ----------
*/
.I4t79h{ align-items: normal     }
.nA9305{ align-items: stretch    }
.d721Vx{ align-items: center     }
.Q3n44O{ align-items: flex-start }
.J7l0G2{ align-items: flex-end   }
.W44s9p{ align-items: start      }
.C6u87n{ align-items: end        }
.Q7Ae62{ align-items: baseline   }
/* 
    ----------
*/
.xD280K{ text-align: start   }
.Q606yt{ text-align: end     }
.vC9r15{ text-align: center  }
.yyO233{ text-align: justify }
/* 
    ----------
*/
.uUG847{ text-transform: lowercase  }
.GzJ197{ text-transform: uppercase  }
.cMq044{ text-transform: capitalize }
.e2M03y{ text-transform: none       }
/*
    ----------
*/
.vF5k47{ text-decoration: none         }
.F83vw7{ text-decoration: dashed       }
.a20K95{ text-decoration: dotted       }
.C71Dm7{ text-decoration: double       }
.El6174{ text-decoration: solid        }
.r9zX81{ text-decoration: overline     }
.sB248t{ text-decoration: wavy         }
.Q3s2S1{ text-decoration: underline    }
.h6T64Y{ text-decoration: line-through }
/*
    ----------
*/
.cB3332{ text-underline-offset: 8px }
/*
   ----------
*/
.q8K60t{ text-overflow: ellipsis }
.faJ087{ text-overflow: clip     }
.m1BW70{ text-overflow: unset    }
/*
    ----------
*/
.U596Kr{ vertical-align: baseline    }
.t0G834{ vertical-align: sub         }
.ebZ371{ vertical-align: super       }
.I7e03O{ vertical-align: top         }
.j844Eb{ vertical-align: text-top    }
.eQ738Y{ vertical-align: middle      }
.E0vm38{ vertical-align: bottom      }
.z56Q50{ vertical-align: text-bottom }
/*
    ----------
*/
.gL0474{ white-space: normal }
.wqA166{ white-space: nowrap }
.Q7a1D3{ white-space: pre    }
/* 
    ----------
*/
.cH8711{ position: relative }
.gBt584{ position: absolute }
.p9V85g{ position: sticky   }
.uK7E77{ position: static   }
.Ude336{ position: fixed    }
/*
    ----------
*/
.x35Ac2{ overflow: visible }
.J057Si{ overflow: hidden  }
.jC732U{ overflow: scroll  }
.L9Rm29{ overflow: auto    }
/*
    ----------
*/
.yL0811{ cursor: alias         }
.v6CN61{ cursor: all-scroll    }
.qE800M{ cursor: auto          }
.tJ204O{ cursor: cell          }
.sDN443{ cursor: col-resize    }
.p8C19l{ cursor: context-menu  }
.E0m26B{ cursor: copy          }
.L567nt{ cursor: crosshair     }
.Ri032C{ cursor: default       }
.kD889h{ cursor: e-resize      }
.S6u0h5{ cursor: ew-resize     }
.c2Q91N{ cursor: grab          }
.dZ9M00{ cursor: grabbing      }
.gM266u{ cursor: help          }
.dP797B{ cursor: move          }
.j3Uh65{ cursor: n-resize      }
.cZ180w{ cursor: ne-resize     }
.aH3174{ cursor: nw-resize     }
.j53Z9D{ cursor: nwse-resize   }
.Gp2446{ cursor: no-drop       }
.Lr9809{ cursor: not-allowed   }
.N4g4m6{ cursor: pointer       }
.k6G6h5{ cursor: progress      }
.Yri682{ cursor: row-resize    }
.Zd4o45{ cursor: s-resize      }
.X2R6j8{ cursor: se-resize     }
.p2M9c8{ cursor: sw-resize     }
.Xw0F20{ cursor: text          }
.Q6j5W4{ cursor: vertical-text }
.S344z0{ cursor: w-resize      }
.z9V38t{ cursor: wait          }
.Ac102a{ cursor: zoom-in       }
.d6F48n{ cursor: zoom-out      }
.h30X35{ cursor: none          }
/*
    ----------
*/
.Kg80G9{ word-wrap: normal     }
.uQ136M{ word-wrap: break-word }
/*
    ----------
*/
.z9X51G{ pointer-events: none }
.Bj1642{ pointer-events: auto }
/*
    ----------
*/
.oQ637t{ border-style: dotted }
.r380oV{ border-style: dashed }
.F5m883{ border-style: solid  }
.Fb292w{ border-style: double }
.Fcx262{ border-style: groove }
.eS845b{ border-style: ridge  }
.L2sq24{ border-style: inset  }
.t3KL23{ border-style: outset }
.Z7o516{ border-style: none   }
.Wx078J{ border-style: hidden }
/*
    ----------
*/
.ob2A34{ border:        0 }
.xq62J3{ border-top:    0 }
.PvN976{ border-bottom: 0 }
.rJ26U8{ border-left:   0 }
.LjJ561{ border-right:  0 }
/*
    ----------
*/
.L72y8b{ border-collapse: collapse }
.Z5y7o1{ border-collapse: separate }
/*
    ----------
*/
.m9X7C5{ margin: 0 auto }
/*
    ----------
*/
.pEO064{ margin:        0 }
.vSV800{ margin-left:   0 }
.D797aR{ margin-right:  0 }
.sX6873{ margin-top:    0 }
.B2d81S{ margin-bottom: 0 }
/*
    ----------
*/
.a527eB{ padding:        0 }
.z8o5S4{ padding-top:    0 }
.dW8M00{ padding-right:  0 }
.C7D19o{ padding-left:   0 }
.R3a5i0{ padding-bottom: 0 }
/*
    ----------
*/
.GO52g1{ border-radius: 0    }
.g03C00{ border-radius: 25%  }
.K79e1C{ border-radius: 50%  }
.pZR189{ border-radius: 75%  }
.kZm977{ border-radius: 100% }
/*
    ----------
*/
.wzU404{ flex: 0 0 auto }
.uTM149{ flex: 1 1 auto }
.U977pl{ flex: 0 1 auto }
.z097Vp{ flex: 1 0 auto }
/*
    ----------
*/
.P1470a{ flex-grow: 0 }
.iw2U41{ flex-grow: 1 }
.I750Xd{ flex-grow: 2 }
.S276Td{ flex-grow: 3 }
/*
    ----------
*/
.X594yq{ flex-basis: 1 }
.U716Tv{ flex-basis: 2 }
.Rzr931{ flex-basis: 3 }
/*
    ----------
*/
.o9T8I8{ flex-wrap: wrap         }
.R0X85t{ flex-wrap: nowrap       }
.Fv9Z90{ flex-wrap: wrap-reverse }
/*
    ----------
*/
.vE6956{ order: 1 }
.XjX424{ order: 2 }
.W85it5{ order: 3 }
/*
    ----------
*/
.Q330w3{ align-content: stretch       }
.v69MS6{ align-content: center        }
.X19jp8{ align-content: space-evenly  }
.v74JJ5{ align-content: flex-start    }
.uH27J4{ align-content: flex-end      }
.T3l2s4{ align-content: space-between }
.D123Qj{ align-content: space-around  }
/*
    ----------
*/
.j1Mr03{ flex-shrink: 0 }
.n7OS97{ flex-shrink: 1 }
/*
    ----------
*/
.Ax30Q1{ box-shadow: none }
/*
    ----------
*/
.J55M9e{ content: "" }
/* 
    ----------
*/
.C4v153{ outline: 0    }
.sD881L{ outline: auto }
/* 
    ----------
*/
.pL4U70{ opacity: 0    }
.q693Lc{ opacity: 25%  }
.aT591i{ opacity: 50%  }
.S8k6H9{ opacity: 75%  }
.aV498I{ opacity: 100% }
/*
    ----------
*/
.cB943h{ -webkit-line-clamp: 2 }
.Bt6232{ -webkit-line-clamp: 3 }
.X2a9R4{ -webkit-line-clamp: 4 }
/*
    ----------
*/
.gDk539{ -webkit-box-orient: block-axis  }
.J4i292{ -webkit-box-orient: inline-axis }
.uQ1c23{ -webkit-box-orient: horizontal  }
.G7o96W{ -webkit-box-orient: vertical    }
/*
    ----------
*/
.c8R6W7{ list-style: decimal-leading-zero }
.WvX121{ list-style: lower-alpha          }
.Iy85Z8{ list-style: lower-greek          }
.X6y6H0{ list-style: lower-latin          }
.xT170D{ list-style: lower-roman          }
.J564hZ{ list-style: upper-alpha          }
.Qd352p{ list-style: upper-latin          }
.Np616D{ list-style: upper-roman          }
.W18b0l{ list-style: disc                 }
.bR099N{ list-style: georgian             }
.Jd2580{ list-style: inside               }
.R723p4{ list-style: outside              }
.dK9146{ list-style: square               }
.h57Y8Q{ list-style: circle               }
.kO539q{ list-style: armenian             }
.vY001R{ list-style: decimal              }
.Au5718{ list-style: numeric              }
.GnG350{ list-style: none                 }
/*
    ----------
*/
.C196Fv{ writing-mode: horizontal-tb }
.Z95a8x{ writing-mode: vertical-lr   }
.x0F757{ writing-mode: vertical-rl   }
/*
    ----------
*/
.Klc797{ transition: all .8s ease }
/*
    ----------
*/
.t0T2S2{ transform: none           }
.wD3v95{ transform: rotate(90deg)  }
.Zka424{ transform: rotate(180deg) }
.v1M46U{ transform: rotate(270deg) }
.j833Ma{ transform: rotate(360deg) }
/*
    ----------
*/
.jX0924{ object-fit: fill       }
.C0go67{ object-fit: contain    }
.Mg1457{ object-fit: cover      }
.uX61k8{ object-fit: scale-down }
.y60E9p{ object-fit: none       }
/* 
    ----------
*/
.qW182T{ line-height: 1.0 }
.tSz942{ line-height: 1.5 }
.YS535e{ line-height: 2.0 }
/*
    ----------
*/
.SFq502{ background-position: left top      }
.iJM161{ background-position: left center   }
.Gr070F{ background-position: left bottom   }
.Px402I{ background-position: right top     }
.t6K867{ background-position: right center  }
.mG848G{ background-position: right bottom  }
.J2l421{ background-position: center top    }
.F710rg{ background-position: center center }
.vW8q13{ background-position: center bottom }
/*
    ----------
*/
.Tq143x{ background-size: auto    }
.T659Fi{ background-size: cover   }
.B4A44p{ background-size: contain }
/*
    ----------
*/
.P6cE22{ background-repeat: repeat    }
.XcJ938{ background-repeat: repeat-x  }
.rYx754{ background-repeat: repeat-y  }
.N965Go{ background-repeat: no-repeat }
.rG790K{ background-repeat: space     }
.kV124B{ background-repeat: round     }
/*
    ----------
*/
.P779mc{ box-sizing: border-box }
/*
    ----------
*/
.H9g95G{ word-break: normal     }
.kO189r{ word-break: break-all  }
.wK1970{ word-break: keep-all   }
.kL64Z1{ word-break: break-word }
/*
    ----------
*/
.q629Gd{ filter: brightness(0.5)   }
.jSO180{ filter: contrast(160%)     }
.H61jT8{ filter: saturate(3)        }
.wM7o82{ filter: invert(100%)       }
.Q8b7e3{ filter: grayscale(50%)     }
.T354Vs{ filter: sepia(100%)        }
.U029ho{ filter: blur(3px)          }
.o98b8U{ filter: hue-rotate(142deg) }
/*
    ----------
*/
.C23pq8{ resize: both       }
.qS03n2{ resize: none       }
.Ss37t2{ resize: horizontal }
.n3R12l{ resize: vertical   }
/*
    ----------
*/
@mixin Size( $Width, $Height ){ 
    width  : $Width; 
    height : $Height 
}
@mixin Start( $Start, $Top ){ 
    left : $Start; 
    top  : $Top 
}
@mixin End( $End, $Top ){ 
    right : $End; 
    top   : $Top 
}
@mixin Top( $Start, $Bottom ){ 
    left   : $Start; 
    bottom : $Bottom 
}
@mixin Bottom( $End, $Bottom ){ 
    right  : $End; 
    bottom : $Bottom 
}
@mixin Margin( $Top, $End, $Bottom, $Start ){ 
    margin: $Top $End $Bottom $Start 
}
@mixin Padding( $Top, $End, $Bottom, $Start ){ 
    padding: $Top $End $Bottom $Start 
}
/* 
    ----------
*/
:root{
    --css-3m71ky : 12.5px;
    --css-wf646z : 13.5px;
    --css-w237qk : 14.5px;
    --css-z44516 : 15.5px;
    --css-r12i8g : 21px;
    --css-g86b00 : 26px;
    --css-80k04n : 40px;
}
:root{
    --css-1a4j4t : #CBC8C2;
    --css-61a8kd : #E6E1D6;
    --css-z7y753 : #4A4946;
    --css-1Go6UK : #646464;
    --css-m7b00d : #8A9198;
}
:root{
    --css-s613d5 : #4267B2;
    --css-an78g4 : #000000;
    --css-y150gn : #0A66C2;
    --css-b2c156 : #9C0343;
} 
:root{
    --css-04j8x5 : transparent
}
/* 
    ----------
*/
html{
    @extend .dE5574;
}
a{
    @extend .v6EU83;
    @extend .vF5k47;
}
a:hover{
    @extend .Q3s2S1;
}
/* 
    ----------
*/
body{
    @extend .dE5574;
    @extend .Wm8G06;
    @extend .rP408N;
    &::-webkit-scrollbar{
        @include Size( 1px, auto );
    }
    &::-webkit-scrollbar-track{
        background: map-get( $Color, "Black" );
    }
    &::-webkit-scrollbar-thumb{
        background: map-get( $Color, "Black" );
    }
}
/* 
    ----------
*/
.b397vj{
    @include Size( 100%, auto );
    @extend .Klc797;
    .jd169t{
        @include Size( 100%, 85px );
        @extend .Klc797;
        @extend .cH8711;
        background: rgba( map-get( $Color, "Dark" ), .2 );
        border-bottom: 1px solid rgba( map-get( $Color, "White" ), .2 );
        z-index: 99;
        .u567vb{
            @include Size( 100%, 90px );
            @extend .Klc797;
            .y1r83c{
                @include Size( 100%, auto );
                @extend .Klc797;
                @extend .C4v153;
                svg{
                    @include Size( 75px, auto );
                    @extend .Klc797;
                    path{
                        @extend .f8T6I8;
                        @extend .Klc797;
                    }
                }
            }
        }
        .z8310x{
            @include Size( 100%, auto );
            @extend .Klc797;
            .y321bf{
                @include Size( 100%, 90px );
                @extend .GnG350;
                li{
                    a{
                        @include Size( 100%, auto );
                        @extend .M99v3q;
                        @extend .K0cW96;
                        @extend .pEO064;
                        @extend .xG0j09;
                        padding: 0 21px;
                        &:hover{
                            @extend .cB3332;
                        }
                    }
                    &:first-child a{
                        @extend .C7D19o;
                    }
                    &:last-child a{
                        @extend .dW8M00;
                    }
                }
            }
        }
        .i83a7c{
            @include Size( 100%, auto );
            @extend .Klc797;
            .w70rv2{
                @include Size( 100%, 90px );
                @extend .GnG350;
                li{
                    a{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        svg{
                            @include Size( 17px, 17px );
                            @extend .Klc797;
                            margin: -2px 0 0 0;
                        }
                    }
                }
            }
            .t98m9l{
                @include Size( 100%, 14px );
                @include End( -12px, -10px );
                @extend .rP408N;
                @extend .vOx602;
                @extend .K0cW96;
                font-size: map-get( $Size, "10" ) - 3px;
                max-width: 14px;
            }
        }
    }
    .jq9p54{
        @include Size( 100%, 55px );
        @extend .u3I753;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        .t4905m{
            @include Size( 100%, 55px );
            @extend .Klc797;
            @extend .C4v153;
            svg{
                @include Size( 25px, 25px );
                @extend .Klc797;
            }
        }
        .v6i522{
            @include Size( 100%, 55px );
            @extend .Klc797;
            @extend .C4v153;
            svg{
                @include Size( 21px, 21px );
                @extend .Klc797;
            }
        }
        .f387gt{
            @include Size( 100%, 55px );
            @extend .Klc797;
            @extend .C4v153;
            svg{
                @include Size( 45px, 45px );
                @extend .Klc797;
                path{
                    @extend .Klc797;
                    @extend .f8T6I8;
                }
            }
        }
        .x2h02i{
            @include Size( 100%, 55px );
            @extend .Klc797;
            @extend .C4v153;
            svg{
                @include Size( 25px, 25px );
                margin: -1px 0 0 0;
            }
        }
        .container .row .col-md-3{
            @include Size( 25%, auto );
        }
        .container .row .col-md-6{
            @include Size( 50%, auto );
        }
    }
    .i64iv0{
        @include Start( 0, 55px );
        @include Size( 100%, 100vh );
        @extend .u3I753;
        border-top: 1px solid var(--css-z7y753);
        z-index: 9999;
        .i1n3m3{
            @include Size( 100%, auto );
            @extend .GnG350;
            li{
                a{
                    @include Size( 100%, 48px );
                    @extend .vF5k47;
                    @extend .Klc797;
                    font-size: var(--css-3m71ky);
                    color: var(--css-1a4j4t);
                    border-bottom: 1px solid var(--css-z7y753);
                    line-height: 48px;
                    svg{
                        @include Size( 15px, 15px );
                        @extend .Klc797;
                    }
                    &:hover{
                        @extend .M99v3q;
                    }
                }
                &:last-child a{
                    @extend .ob2A34;
                }
            }
        }
    }
}
/* 
    ----------
*/
.j64fh9{
    @include Size( 100%, auto );
    @extend .Klc797;
    .A8vD2r{
        @include Size( 100%, 350px );
        @extend .i3eE58;
        .jWw0y4{
            @include Size( 100%, 350px );
            @extend .Klc797;
        }
        .GYBm67{
            @include Size( 100%, 350px );
            @extend .Klc797;
            background: var(--css-61a8kd);
        }
        .s4lCEP{
            @include Size( 100%, 100% );
            @extend .Klc797;
            .Yy2TKZ{
                @extend .wI860z;
                @extend .vOx602;
                @extend .K0cW96;
                line-height: 30px
            }
            .rSW7Yc{
                @extend .vOx602;
                @extend .nS533w;
                @extend .xG0j09;
                line-height: 23px;
            }
            .OO3Kdm{
                @extend .vOx602;
                @extend .nS533w;
                @extend .xG0j09;
                @extend .Q3s2S1;
                @extend .cB3332;
                line-height: 23px;
                &:hover{
                    @extend .vF5k47;
                }
            }
        }
    }
    .w4552r{
        @include Size( 100%, auto );
        @extend .Klc797;
        .iga296{
            @include Size( 100%, 416px );
            @extend .i3eE58;
            video{
                @include Size( 100%, 100% );
                @extend .Mg1457;
            }
        }
        .b3d6s6{
            @include Size( 100%, 416px );
            @extend .Klc797;
            .o37v2n{
                @extend .Wm8G06;
                @extend .vOx602;
                @extend .K0cW96;
            }
            .t7i77g{
                font-size: var(--css-wf646z);
                color: var(--css-1Go6UK);
                line-height: 23.5px;
            }
            .o10v60{
                @include Size( 100%, auto );
                @extend .xG0j09;
                @extend .vF5k47;
                @extend .Q3s2S1;
                @extend .cB3332;
                color: var(--css-1Go6UK);
                strong{
                    @extend .nS533w;
                }
                &:hover{
                    @extend .vF5k47;
                }
            }
            .g43785{
                @include Size( 100%, 18px );
                @extend .rP408N;
                border: 1px solid var(--css-1Go6UK);
                max-width: 18px;
            }
        }
    }
    .r383mb{
        @include Size( 100%, auto );
        @extend .gZ959C;
        @extend .a527eB;
        margin: -100px 0 0 0;
        .mau703{
            @include Size( 100%, auto );
            @extend .cH8711;
            &::before{
                @include Start( 0, 0 );
                @include Size( 100%, 100% );
                @extend .J55M9e;
                @extend .S6pt64;
                @extend .gBt584;
                background: rgba( map-get( $Color, "Black" ), .28 );
            }
            .t748jl{
                @include Size( 100%, auto );
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                }
            }
            .w9u19m{
                @include Start( 0, 0 );
                @include Size( 100%, 100% );
                @extend .Klc797;
                .w44r88{
                    @include Size( 100%, 100% );
                    @extend .Klc797;
                    .n5e3z9{
                        @include Size( 100%, 100% );
                        @extend .Klc797;
                        .k28362{
                            @extend .M99v3q;
                            @extend .K0cW96;
                            font-size: var(--css-80k04n);
                            line-height: 56px;
                            max-width: 460px
                        }
                        .a4396d{
                            @extend .M99v3q;
                            @extend .nS533w;
                            @extend .S8k6H9;
                            @extend .PlO170;
                            max-width: 460px;
                            line-height: 30px
                        }
                        .z274br{
                            @include Size( 100%, 53px );
                            @extend .Klc797;
                            @extend .C4v153;
                            @extend .rP408N;
                            @extend .PlO170;
                            @extend .K0cW96;
                            border: 2px solid map-get( $Color, "White" );
                            max-width: 220px;
                            border-radius: 53px;
                            &:hover{
                                @extend .M99v3q;
                                background: var(--css-04j8x5);
                            }
                        }
                    }
                    .v258gv{
                        @include Size( 100%, 100% );
                        @extend .Klc797;
                        .y02vy3{
                            @include Size( 100%, auto );
                            @extend .Klc797;
                            .swiper-pagination-bullet{
                                @include Size( 28px, 28px );
                                @extend .cH8711;
                                @extend .rP408N;
                                @extend .S0u16d;
                                @extend .vR05U6;
                                @extend .d721Vx;
                                @extend .aV498I;
                                background: var(--css-04j8x5);
                                margin: 0 0 10px 0;
                                z-index: 99;
                                &:last-child{
                                    margin: 0;
                                }
                                &::before{
                                    @include Size( 5px, 5px );
                                    @extend .J55M9e;
                                    @extend .rP408N;
                                    @extend .kZm977;
                                    @extend .wDC543;
                                }
                            }
                            .swiper-pagination-bullet-active{
                                border: 2px solid map-get( $Color, "White" );
                            }
                        }
                        .c7ar92{
                            @include Size( 100%, 725px );
                            @extend .Klc797;
                            .t003r7{
                                @include Size( 100%, 28px );
                                @extend .Klc797;
                                @extend .C4v153;
                                @extend .a527eB;
                                @extend .ob2A34;
                                @extend .rP408N;
                                margin: 0 0 17px 0;
                                max-width: 28px;
                                z-index: 99;
                                &:hover{
                                    @extend .u3I753;
                                    &::before{
                                        content: url( "./Media/Icon/006.svg" );
                                    }
                                }
                                &::before{
                                    @include Size( 100%, 100% );
                                    @extend .S0u16d;
                                    @extend .vR05U6;
                                    @extend .d721Vx;
                                    @extend .a527eB;
                                    content: url( "./Media/Icon/005.svg" );
                                    margin: 0 0 0 1.58px;
                                }
                            }
                            .w181kh{
                                @include Size( 100%, 28px );
                                @extend .Klc797;
                                @extend .C4v153;
                                @extend .a527eB;
                                @extend .ob2A34;
                                @extend .ob2A34;
                                @extend .rP408N;
                                margin: 17px 0 0 0;
                                max-width: 28px;
                                z-index: 99;
                                &:hover{
                                    @extend .u3I753;
                                    &::before{
                                        content: url( "./Media/Icon/007.svg" );
                                    }
                                }
                                &::before{
                                    @include Size( 100%, 100% );
                                    @extend .S0u16d;
                                    @extend .vR05U6;
                                    @extend .d721Vx;
                                    @extend .a527eB;
                                    content: url( "./Media/Icon/004.svg" );
                                    margin: 2px 0 0 1.58px;
                                }
                            }
                        }
                    }
                }
            }
            .bl39u4{
                @include Size( 100%, 100% );
                @extend .Klc797;
                .xt42k1{
                    @include Start( 25%, 0 );
                    @include Size( 1px, 100% );
                    border-right: 1px solid rgba( map-get( $Color, "White" ), .2 );
                    min-width: 1px;
                }
                .b66326{
                    @include Start( 50%, 0 );
                    @include Size( 1px, 100% );
                    border-right: 1px solid rgba( map-get( $Color, "White" ), .2 );
                    min-width: 1px;
                }
                .gu254k{
                    @include Start( 75%, 0 );
                    @include Size( 1px, 100% );
                    border-right: 1px solid rgba( map-get( $Color, "White" ), .2 );
                    min-width: 1px;
                }
            }
        }
    }
    .i5834n{
        @include Size( 100%, auto );
        @extend .Klc797;
        .j87b80{
            @include Size( 100%, 401px );
            @extend .gZ959C;
        }
        .x2g758{
            @include Size( 100%, auto );
            @extend .Klc797;
            .TQQa4G{
                @include Size( 100%, auto );
                border: 1px solid var(--css-m7b00d);
                .s66l57{
                    @include Size( 100%, 100% );
                    @extend .rP408N;
                }
                .tVI0Qi{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .W6E3mt{
                        @include Size( 100%, auto );
                        @extend .iI0402;
                        color: var(--css-1Go6UK);
                        line-height: 23px
                    }
                    .Ha7OWF{
                        @extend .vOx602;
                        @extend .K0cW96;
                        @extend .xG0j09;
                        line-height: 22px
                    }
                    .U699vt{
                        @include Size( 100%, 36px );
                        @extend .rP408N;
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .iI0402;
                        @extend .vOx602;
                        @extend .K0cW96;
                        @extend .vF5k47;
                        border: 1px solid map-get( $Color, "Black" );
                        max-width: 140px;
                        border-radius: 36px;
                        line-height: 34px;
                    }
                }
                &:hover .U699vt{
                    @extend .u3I753;
                    color: map-get( $Color, "White" );
                }
                &:hover .Ha7OWF{
                    @extend .Q3s2S1;
                }
            }
            .uF4STK{
                @include Size( 100%, auto );
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                }
            }
            .h8j670{
                @include Size( 100%, 481.5px );
                @extend .Klc797;
                .kd539k{
                    @extend .Wm8G06;
                    @extend .vOx602;
                    @extend .K0cW96;
                    line-height: 24px;
                }
                .ay75v7{
                    font-size: var(--css-wf646z);
                    color: var(--css-1Go6UK);
                    line-height: 23px;
                }
                .h64061{
                    @extend .cB3332;
                    font-size: var(--css-wf646z);
                    color: var(--css-1Go6UK);
                    line-height: 23px;
                }
            }
        }
    }
    .hm2544{
        @include Size( 100%, auto );
        @extend .i3eE58;
        .b87i0d{
            @include Size( 100%, auto );
            @extend .Klc797;
            background: var(--css-61a8kd);
            border-radius: 8px;
            .o8437e{
                @extend .Wm8G06;
                @extend .vOx602;
                @extend .K0cW96;
                line-height: 28px
            }
            .z96m9l{
                @extend .vOx602;
                @extend .nS533w;
                font-size: var(--css-wf646z);
                line-height: 23.5px
            }
            .q656t5{
                @include Size( 100%, 34px );
                @extend .Klc797;
                @extend .kZm977;
                border: 1px solid map-get( $Color, "Black" );
                max-width: 34px;
                svg{
                    @include Size( 13px, 13px );
                    @extend .Klc797;
                    padding: 1px 0 0 1px;
                    path{
                        @extend .sRU313;
                    }
                }
            }
            &:hover .q656t5{
                @extend .u3I753;
                svg path{
                    @extend .f8T6I8;
                }
            }
        }
    }
    .hX85rr{
        @include Size( 100%, auto );
        @extend .Klc797;
        .y5WIzQ{
            @include Size( 100%, auto );
            @extend .gZ959C;
            video{
                @include Size( 100%, auto );
                @extend .Mg1457;
            }
        }
        .zX2EQz{
            @include Size( 100%, 100% );
            @extend .i3eE58;
            .oSb1Ts{
                @include Size( 100%, 44px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .vOx602;
                @extend .xG0j09;
                @extend .ob2A34;
                background: var(--css-61a8kd);
                border-radius: 4px;
                &::placeholder{
                    @extend .vOx602;
                    @extend .aV498I;
                }
            }
            .tI4TZu{
                @include Size( 100%, 110px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .vOx602;
                @extend .xG0j09;
                @extend .ob2A34;
                background: var(--css-61a8kd);
                border-radius: 4px;
                &::placeholder{
                    @extend .vOx602;
                    @extend .aV498I;
                }
            }
            .e5M8We{
                @include Size( 100%, 52px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .u0Bc59;
                @extend .ob2A34;
                @extend .xG0j09;
                @extend .M99v3q;
                @extend .K0cW96;
                border-radius: 4px;
                &:hover{
                    @extend .u3I753;
                }
            }
            .Ecx0eZ{
                @include Size( 100%, auto );
                @extend .Klc797;
                .Bf17bv{
                    @extend .xG0j09;
                    @extend .vOx602;
                    @extend .nS533w;
                }
            }
            .WGjL7M{
                @extend .xG0j09;
                @extend .vOx602;
                @extend .K0cW96;
            }
        }
    }
    .p91zc1{
        @include Size( 100%, auto );
        @extend .Klc797;
        .y6z998{
            @include Size( 100%, auto );
            @extend .rP408N;
            .g659s4{
                @include Size( 100%, auto );
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                    @extend .kZm977;
                }
            }
            .o7z358{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-wf646z);
            }
        }
        .container .row .col-md-2{
            @include Size( 12.5%, auto );
        }
    }
}
/* 
    ----------
*/
.e66x2v{
    @include Size( 100%, 48px );
    @extend .i3eE58;
    .x0i603{
        @include Size( 100%, 48px );
        @extend .GnG350;
        li{
            a{
                @extend .iI0402;
                @extend .vOx602;
                @extend .nS533w;
            }
            span{
                @extend .iI0402;
                @extend .vOx602;
                @extend .nS533w;
            }
            svg{
                @include Size( 11px, 11px );
                @extend .Klc797;
                path{
                    @extend .sRU313;
                }
            }
        }
    }
}
.wo9836{
    @include Size( 100%, auto );
    @extend .Klc797;
    .n3186t{
        @include Size( 100%, auto );
        @extend .Klc797;
        .o625uj{
            @extend .vOx602;
            @extend .K0cW96;
            font-size: var(--css-g86b00);
            line-height: 36px;
        }
        .s28e8x{
            @extend .vOx602;
            @extend .K0cW96;
            font-size: var(--css-r12i8g);
        }
        .s4096n{
            @extend .PlO170;
            @extend .vOx602;
            @extend .yyO233;
            line-height: 25px
        }
        .s4to31{
            @include Size( 100%, 44px );
            @extend .Klc797;
            @extend .u0Bc59;
            @extend .ob2A34;
            @extend .xG0j09;
            @extend .M99v3q;
            @extend .K0cW96;
            @extend .vF5k47;
            max-width: 180px;
            border-radius: 44px;
            &:hover{
                @extend .u3I753;
            }
        }
        .u646ho{
            @include Size( 100%, auto );
            @extend .Klc797;
            .p0025l{
                @include Size( 100%, auto );
                @extend .Klc797;
                .v3918a{
                    @include Size( 100%, 122px );
                    @extend .Klc797;
                    border: 1px solid var(--css-1Go6UK);
                    img{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                    }
                }
                .i01u47{
                    @extend .xG0j09;
                    @extend .vOx602;
                    @extend .K0cW96;
                }
            }
            .row .col-md-3{
                @include Size( 20%, auto );
            }
        }
    }
    .q072j5{
        @include Size( 100%, auto );
        @extend .Klc797;
        .c24673{
            @include Size( 100%, auto );
            @extend .Klc797;
        }
        .c4eh00{
            @include Size( 100%, auto );
            @extend .Klc797;
            img{
                @include Size( 100%, auto );
                @extend .Klc797;
            }
        }
    }
    .c2l68g{
        @include Size( 100%, 100px );
        @extend .Klc797;
    }
    .c558jd{
        @include Size( 100%, auto );
        @extend .i3eE58;
    }
    .wy1q82{
        @include Size( 100%, auto );
        @extend .gZ959C;
    }
    .c1349i{
        @extend .PlO170;
        @extend .vOx602;
        @extend .K0cW96;
    }
    .j65d9q{
        font-size: var(--css-wf646z);
        color: var(--css-z7y753);
        line-height: 23px
    }
    .q4674j{
        @extend .Q3s2S1;
        @extend .cB3332;
        font-size: var(--css-wf646z);
        color: var(--css-z7y753);
        line-height: 23px
    }
    .g410b3{
        @include Size( 100%, auto );
        @extend .Klc797;
    }
    .b98853{
        @include Size( 100%, auto );
        @extend .Klc797;
        .u2r3l1{
            @include Size( 100%, auto );
            border: 1px solid var(--css-m7b00d);
            .v0116i{
                @include Size( 100%, 132px );
                @extend .gZ959C;
                img{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                }
            }
            .s5p5x1{
                @include Size( 100%, 132px );
                @extend .Klc797;
                .m036r3{
                    @extend .xG0j09;
                    @extend .vOx602;
                    @extend .K0cW96;
                    line-height: 23px
                }
            }
            &:hover .m036r3{
                @extend .Q3s2S1;
            }
        }
    }
    .c501g2{
        @include Size( 100%, auto );
        @extend .Klc797;
        img{
            @include Size( 100%, auto );
            @extend .Klc797;
        }
    }
    .WGjL7M{
        @extend .xG0j09;
        @extend .vOx602;
        @extend .K0cW96;
    }
}
.q9s876{
    @include Size( 100%, 70px );
    @extend .rP408N;
    box-shadow: 0 6px 12px rgba( map-get( $Color, "Dark" ), .13 );
    border-radius: 12px;
    min-width: 200px;
    .e05z8a{
        @include Size( 100%, 100% );
        @extend .GnG350;
        li{
            a{
                @include Size( 100%, auto );
                @extend .a527eB;
                margin: 0 0 0 26px;
                svg{
                    @include Size( 20px, 20px );
                    @extend .Klc797;
                    path{
                        @extend .Klc797;
                        fill: var(--css-1Go6UK);
                    }
                }
                &:hover svg path{
                    fill: var(--css-z7y753);
                }
            }
            &:first-child a{
                margin: 0;
            }
            &:nth-child(2) a svg{
                @include Size( 18px, 18px );
            }
            &:last-child a svg{
                @include Size( 19px, 19px );
            }
        }
    }
}
.da104x{
    @include Size( 100%, auto );
    @extend .i3eE58;
    .e06m9j{
        @include Size( 100%, auto );
        @extend .Klc797;
        .v64ff5{
            @include Size( 100%, auto );
            @extend .Klc797;
            .e5j51g{
                @include Size( 100%, 44px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .vOx602;
                @extend .xG0j09;
                @extend .ob2A34;
                background: var(--css-61a8kd);
                border-radius: 4px;
                &::placeholder{
                    @extend .vOx602;
                    @extend .aV498I;
                }
            }
            .e437x9{
                @include Size( 100%, 44px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .vOx602;
                @extend .xG0j09;
                @extend .ob2A34;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                background: url( "./Media/Icon/011.svg" ) no-repeat right var(--css-61a8kd);
                background-position-x: 98%;
                border-radius: 4px;
                option{
                    @extend .i3eE58;
                    @extend .wDC543;
                    @extend .ob2A34;
                }
            }
            .z6f978{
                @include Size( 100%, 140px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .vOx602;
                @extend .xG0j09;
                @extend .ob2A34;
                background: var(--css-61a8kd);
                border-radius: 4px;
                &::placeholder{
                    @extend .vOx602;
                    @extend .aV498I;
                }
            }
            .k3u18p{
                @include Size( 100%, 52px );
                @extend .Klc797;
                @extend .C4v153;
                @extend .u0Bc59;
                @extend .ob2A34;
                @extend .xG0j09;
                @extend .M99v3q;
                @extend .K0cW96;
                border-radius: 4px;
                &:hover{
                    @extend .u3I753;
                }
            }
            .mz688t{
                @extend .xG0j09;
                @extend .vOx602;
                @extend .K0cW96;
            }
        }
    }
}
.j526i9{
    @include Size( 100%, auto );
    @extend .rP408N;
    .d6203g{
        @include Size( 100%, auto );
        @extend .Klc797;
        img{
            @include Size( 100%, auto );
            @extend .Klc797;
        }
    }
    .n825zt{
        @include Size( 100%, 422px );
        @extend .Klc797;
        .q97n5j{
            @extend .PlO170;
            @extend .nS533w;
            color: var(--css-1Go6UK);
        }
        .t5t52g{
            @extend .vOx602;
            @extend .K0cW96;
            @extend .i260Up;
            line-height: 31px
        }
        .p96532{
            @extend .vOx602;
            @extend .nS533w;
            font-size: var(--css-wf646z);
            line-height: 23px
        }
        .k38o9g{
            @include Size( auto, 44px );
            @extend .rP408N;
            @extend .xG0j09;
            @extend .vOx602;
            @extend .vF5k47;
            @extend .Klc797;
            border: 1px solid var(--css-1Go6UK);
            border-radius: 44px;
            line-height: 44px;
            &:hover{
                @extend .u3I753;
                @extend .M99v3q;
            }
        }
    }
}
.e1f54e{
    @include Size( 100%, auto );
    @extend .Klc797;
    .t4q95f{
        @include Size( 100%, auto );
        @extend .Klc797;
        .w298l4{
            @include Size( 100%, auto );
            @extend .GnG350;
            li{
                @include Size( 100%, 45px );
                @extend .i3eE58;
                &:nth-child( 2n+1 ){
                    @extend .gZ959C;
                }
                &:first-child{
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
                &:last-child{
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
        .k02v89{
            @include Size( 100%, auto );
            @extend .Klc797;
            .w3m19z{
                @extend .iI0402;
                @extend .vOx602;
                @extend .K0cW96;
                line-height: 45px;
                &::after{
                    @extend .iI0402;
                    @extend .vOx602;
                    @extend .yJ452A;
                    content: ":";
                }
            }
            .a688om{
                @extend .iI0402;
                @extend .vOx602;
                @extend .K0cW96;
                line-height: 45px;
            }
        }
    }
}
.v861fe{
    @include Size( 100%, auto );
    @extend .Klc797;
    .v309s2{
        @include Size( 100%, auto );
        @extend .GnG350;
        li{
            a{
                @include Size( 100%, 38px );
                @extend .i3eE58;
                @extend .ob2A34;
                @extend .Klc797;
                @extend .C4v153;
                min-width: 38px;
                svg{
                    @include Size( 15px, 15px );
                    path{
                        @extend .f8T6I8;
                    }
                }
            }
            &:first-child a{
                background: var(--css-s613d5);
            }
            &:nth-child(2) a{
                background: var(--css-an78g4);
                svg{
                    @include Size( 14px, 14px );
                }
            }
            &:last-child a{
                background: var(--css-b2c156);
                svg{
                    @include Size( 14px, 14px );
                    margin: 0 0 0 1px;
                }
            }
        }
    }
}
/* 
    ----------
*/
.zh731r{
    @include Size( 100%, auto );
    @extend .Klc797;
    .p920w3{
        @include Size( 100%, auto );
        @extend .u3I753;
        .uw27j9{
            @include Size( 100%, auto );
            @extend .Klc797;
        }
        .w42bk5{
            @include Size( 100%, 100% );
            @extend .Klc797;
            .b205p1{
                @include Size( 100%, calc( 100% - 81px ) );
                max-width: 1px;
                background: var(--css-z7y753);
            }
        }
        .m7373k{
            @include Size( 100%, auto );
            @extend .Klc797;
            .k05ss5{
                @include Size( 100%, auto );
                @extend .xG0j09;
                @extend .M99v3q;
                @extend .K0cW96;
            }
            .b61409{
                @include Size( 100%, auto );
                @extend .Klc797;
                @extend .a527eB;
                @extend .vF5k47;
                font-size: var(--css-3m71ky);
                color: var(--css-1a4j4t);
                line-height: 23px
            }
            .m0947t{
                @include Size( 100%, auto );
                @extend .Klc797;
                .d911nu{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .tv890h{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        svg{
                            @include Size( 25px, 25px);
                        }
                    }
                    .c83375{
                        @extend .K0cW96;
                        @extend .cB3332;
                        @extend .Q3s2S1;
                        @extend .xG0j09;
                        color: var(--css-1a4j4t);
                    }
                }
            }
            .d9o0s6{
                @include Size( 100%, auto );
                @extend .GnG350;
                li{
                    a{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        @extend .a527eB;
                        @extend .vF5k47;
                        font-size: var(--css-3m71ky);
                        color: var(--css-1a4j4t);
                        margin: 0 0 9px;
                        line-height: 19px;
                        &:hover{
                            @extend .M99v3q;
                        }
                    }
                }
            }
            .h45j3c{
                @include Size( 100%, auto );
                @extend .Klc797;
            }
        }
        .t405qk{
            @include Size( 100%, auto );
            @extend .Klc797;
            border-top: 1px solid var(--css-z7y753);
            .u2p43p{
                @include Size( 100%, auto );
                font-size: var(--css-3m71ky);
                color: var(--css-1a4j4t);
            }
        }
    }
}
