@media (min-width: 1920px) and (max-width: 1925px){

}
@media (min-width: 1366px) and (max-width: 1367px){
    .y321bf li a{ padding: 0 14px!important }
    .y321bf li:first-child a{ padding-left: 0!important }
    .y321bf li:last-child a{ padding-right: 0!important }
    .s4lCEP{ padding: 0 32px!important }
    .rSW7Yc, .ay75v7{ display: -webkit-box }
    .rSW7Yc, .ay75v7{ -webkit-box-orient: vertical }
    .rSW7Yc, .ay75v7, .o7z358{ overflow: hidden }
    .rSW7Yc{ -webkit-line-clamp: 3 }
    .t7i77g br{ display: none }
    .t7i77g, .o10v60{ font-size: 12.5px!important }
    .t7i77g{ line-height: 22.5px!important }
    .kd539k{ font-size: 14px!important }
    .o37v2n{ font-size: 15px!important }
    .ay75v7, .o7z358{ font-size: 13px!important }
    .x2g758, .h8j670{ height: 100%!important }
    .h8j670{ align-items: start!important }
    .ay75v7, .Ha7OWF{ text-align: start!important }
    .ay75v7{ width: 100%!important }
    .h64061, .ay75v7, .Ha7OWF{ font-size: 12px!important }
    .ay75v7, .Ha7OWF{ line-height: 22px!important }
    .tVI0Qi{ align-items: start!important }
    .o7z358{ text-overflow: ellipsis }
    .o7z358{ white-space: nowrap }
    .o625uj{ font-size: 21px!important }
    .o625uj{ line-height: 31px!important }
    .s4096n, .q97n5j, .p96532{ font-size: 13px!important }
    .s4096n{ line-height: 23px!important }
    .s4096n{ margin-top: 18px!important }
    .v0116i, .v3918a{ height: auto!important }
    .t5t52g{ font-size: 18px!important }
    .t5t52g{ line-height: 28px!important }
    .p96532 br{ display: none!important }
}
@media (min-width: 1280px) and (max-width: 1281px){
    .y321bf li a{ padding: 0 14px!important }
    .y321bf li:first-child a{ padding-left: 0!important }
    .y321bf li:last-child a{ padding-right: 0!important }
    .y1r83c svg{ width: 55px!important }
    .jd169t .container .row .col-md-3{ width: 15%!important }
    .jd169t .container .row .col-md-7{ width: 68.33333333%!important }
    .w70rv2 li a svg{ width: 15px!important }
    .w70rv2 li a svg{ height: 15px!important }
    .k28362{ font-size: 36px!important }
    .k28362{ line-height: 54px!important }
    .k28362{ max-width: 390px!important }
    .z274br{ height: 45px!important }
    .z274br{ max-width: 200px!important }
    .z274br{ border-radius: 45px!important }
    .z274br{ font-size: 13.5px!important }
    .c7ar92{ padding: 0!important }
    .jd169t{ background: rgba( 51, 51, 51, 0.8 )!important }
    .r383mb{ margin: 0!important }
    .w9u19m{ padding: 0!important }
    .c7ar92{ height: 632.8px!important }
    .t003r7::before{ margin: 4.5px 0 0 1.58px!important }
    .w181kh::before{ margin: 8px 0 0 1.58px!important }
    .Yy2TKZ{ font-size: 13.5px!important }
    .Yy2TKZ{ line-height: 23px!important }
    .jWw0y4, .GYBm67{ width: 33.3%!important }
    .A8vD2r, .jWw0y4, .GYBm67{ height: auto!important }
    .jWw0y4, .GYBm67{ padding: 50px 0!important }
    .A8vD2r .jWw0y4:first-child{ display: none!important }
    .b3d6s6{ height: 100%!important }
    .b3d6s6{ justify-content: center!important }
    .i5834n .container .row .col-md-8{ width: 50%!important }
    .i5834n .container .row .col-md-4{ width: 50%!important }
    .x2g758, .h8j670{ height: 100%!important }
    .d9o0s6 li a, .o7z358{ overflow: hidden }
    .d9o0s6 li a, .o7z358{ text-overflow: ellipsis }
    .d9o0s6 li a, .o7z358{ white-space: nowrap }
    .o7z358{ font-size: 13px!important }
    .hm2544 .container .row .col-md-4{ width: 33.3%!important }
    .oSb1Ts{ font-size: 12px!important }
    .hm2544 .container .row .col-md-4:nth-child(3) .b87i0d{ margin: 12px 0!important }
    .o625uj{ font-size: 21px!important }
    .o625uj{ line-height: 31px!important }
    .s4096n{ font-size: 13.5px!important }
    .s4096n{ line-height: 24px!important }
    .s4096n{ margin-top: 18px!important }
    .v0116i{ height: auto!important }
    .v0116i{ background: transparent!important }
    .s5p5x1{ height: 100%!important }
    .u646ho .row .col-md-2{ width: 20%!important }
    .v3918a{ height: auto!important }
    .q97n5j{ font-size: 13.5px!important }
    .t5t52g{ font-size: 18.5px!important }
    .t5t52g{ line-height: 27px!important }
    .p96532 br{ display: none }
    .p96532{ font-size: 13.4px!important }
    .p96532{ line-height: 24px!important }
    .k38o9g{ height: 42px!important }
    .k38o9g{ border-radius: 42px!important }
    .k38o9g{ line-height: 40px!important }
    .t7i77g br{ display: none }
    .z96m9l{ font-size: 13px!important }
    .z96m9l{ line-height: 23px!important }
}
@media (min-width: 1025px) and (max-width: 1279px){

}
@media (min-width: 992px) and (max-width: 1024px){
    .y1r83c svg{ width: 55px!important }
    .jd169t .container .row .col-md-3{ width: 15%!important }
    .jd169t .container .row .col-md-7{ width: 68.33333333%!important }
    .y321bf li a{ padding: 0 15px!important }
    .y321bf li:first-child a{ padding-left: 0!important }
    .y321bf li:last-child a{ padding-right: 0!important }
    .y321bf li a{ font-size: 12px!important }
    .w70rv2 li a svg{ width: 15px!important }
    .w70rv2 li a svg{ height: 15px!important }
    .k28362{ font-size: 36px!important }
    .k28362{ line-height: 54px!important }
    .k28362{ max-width: 390px!important }
    .z274br{ height: 45px!important }
    .z274br{ max-width: 200px!important }
    .z274br{ border-radius: 45px!important }
    .z274br{ font-size: 13.5px!important }
    .c7ar92{ padding: 0!important }
    .jd169t{ background: rgba( 51, 51, 51, 0.8 )!important }
    .r383mb{ margin: 0!important }
    .w9u19m{ padding: 0!important }
    .c7ar92{ height: 504px!important }
    .t003r7::before{ margin: 4.5px 0 0 1.58px!important }
    .w181kh::before{ margin: 8px 0 0 1.58px!important }
    .Yy2TKZ{ font-size: 13.5px!important }
    .Yy2TKZ{ line-height: 23px!important }
    .jWw0y4, .GYBm67{ width: 50%!important }
    .A8vD2r, .jWw0y4, .GYBm67{ height: auto!important }
    .jWw0y4, .GYBm67{ padding: 50px 0!important }
    .w4552r .container .row .col-md-4:nth-child(3){ display: none }
    .w4552r .container .row .col-md-4{ width: 50%!important }
    .b3d6s6{ height: 100%!important }
    .b3d6s6{ justify-content: center!important }
    .i5834n .container .row .col-md-8{ width: 50%!important }
    .i5834n .container .row .col-md-4{ width: 50%!important }
    .x2g758, .h8j670{ height: 100%!important }
    .d9o0s6 li a, .o7z358{ overflow: hidden }
    .d9o0s6 li a, .o7z358{ text-overflow: ellipsis }
    .d9o0s6 li a, .o7z358{ white-space: nowrap }
    .o7z358{ font-size: 13px!important }
    .hm2544 .container .row .col-md-4{ width: 50%!important }
    .oSb1Ts{ font-size: 12px!important }
    .hm2544 .container .row .col-md-4:nth-child(3) .b87i0d{ margin: 12px 0!important }
    .o625uj{ font-size: 18.9px!important }
    .o625uj{ line-height: 29px!important }
    .s4096n{ font-size: 13px!important }
    .s4096n{ line-height: 23px!important }
    .s4096n{ margin-top: 18px!important }
    .v0116i{ height: auto!important }
    .v0116i{ background: transparent!important }
    .b98853 .row .col-md-6{ width: 100%!important }
    .b98853 .row .col-md-6 .u2r3l1 .col-md-6{ width: 50%!important }
    .s5p5x1{ height: 100%!important }
    .u646ho .row .col-md-2{ width: 20%!important }
    .v3918a{ height: auto!important }
    .q97n5j{ font-size: 13px!important }
    .t5t52g{ font-size: 17px!important }
    .t5t52g{ line-height: 27px!important }
    .p96532 br{ display: none }
    .p96532{ font-size: 13.4px!important }
    .p96532{ line-height: 24px!important }
    .k38o9g{ height: 40px!important }
    .k38o9g{ border-radius: 40px!important }
    .k38o9g{ line-height: 38px!important }
}
@media (min-width: 801px) and (max-width: 991.2px){
    .y1r83c svg{ width: 50px!important }
    .y321bf li a{ font-size: 12px!important }
    .y321bf li:nth-child(6), .w70rv2 li{ display: none }
    .w70rv2 li:nth-child(1){ display: block!important }
    .jd169t .container .row .col-md-3{ width: 15%!important }
    .jd169t .container .row .col-md-2{ width: 6.66666667%!important }
    .jd169t .container .row .col-md-7{ width: 78.33333333%!important }
    .y321bf li a{ padding: 0 13px!important }
    .y321bf li:first-child a{ padding-left: 0!important }
    .y321bf li:last-child a{ padding-right: 0!important }
    .jd169t{ background: rgba( 51, 51, 51, 0.8 )!important }
    .r383mb{ margin: 0!important }
    .k28362{ font-size: 32px!important }
    .k28362{ line-height: 48px!important }
    .k28362, .a4396d{ max-width: 400px!important }
    .a4396d{ font-size: 13px!important }
    .z274br{ height: 48px!important }
    .z274br{ border-radius: 48px!important }
    .z274br{ max-width: 200px!important }
    .c7ar92{ height: 488px!important }
    .t003r7::before{ margin: 5px 0 0 1.58px!important }
    .w181kh::before{ margin: 8px 0 0 1.58px!important }
    .A8vD2r, .jWw0y4, .GYBm67{ height: auto!important }
    .jWw0y4, .GYBm67{ width: 50%!important }
    .jWw0y4, .GYBm67{ padding: 35px 0!important }
    .w4552r .container .row .col-md-4{ width: 100%!important }
    .rSW7Yc{ display: -webkit-box }
    .rSW7Yc{ overflow: hidden }
    .rSW7Yc{ -webkit-box-orient: vertical }
    .rSW7Yc{ -webkit-line-clamp: 2 }
    .w4552r .container .row .col-md-4:first-child .b3d6s6{ height: 150px!important }
    .b3d6s6{ height: auto!important }
    .b3d6s6, .iga296{ margin: 0!important }
    .b3d6s6{ justify-content: center!important }
    .iga296, .h8j670{ height: auto!important }
    .t7i77g br{ display: none }
    .b3d6s6{ align-items: center!important }
    .t7i77g{ width: 100%!important }
    .t7i77g{ text-align: center!important }
    .o10v60{ justify-content: center!important }
    .t7i77g, .h8j670{ margin: 30px 0 0 0!important }
    .i5834n .container .row .col-md-4 .x2g758{ margin: 10px 0 0 0!important }
    .x2g758{ margin: 25px 0 0 0!important }
    .o10v60, .ay75v7, .h64061{ margin: 10px 0 0 0!important }
    .i5834n .container .row .col-md-8, .hX85rr .row .col-md-7{ width: 100%!important }
    .i5834n .container .row .col-md-4, .hX85rr .row .col-md-5{ width: 100%!important }
    .i5834n .container .row .col-md-6{ width: 100%!important }
    .uF4STK, .zX2EQz{ margin: 32px 0 0 0!important }
    .TQQa4G .s66l57 .row .col-md-6{ width: 50%!important }
    .hm2544 .container .row .col-md-4{ width: 100%!important }
    .hm2544 .container .row .col-md-4:nth-child(2) .b87i0d{ margin: 0!important }
    .hm2544 .container .row .col-md-4:last-child .b87i0d{ margin: 24px 0 0 0!important }
    .j64fh9 .p91zc1 .container .row .col-md-2{ width: 20%!important }
    .o7z358{ font-size: 13px!important }
    .o7z358, .d9o0s6 li a, .u2p43p{ overflow: hidden }
    .o7z358, .d9o0s6 li a, .u2p43p{ text-overflow: ellipsis }
    .o7z358, .d9o0s6 li a, .u2p43p{ white-space: nowrap }
    .w70rv2 li a svg{ width: 15px!important }
    .w70rv2 li a svg{ height: 15px!important }
    .uw27j9 .row .col-md-3{ width: 33.3%!important }
    .w70rv2 li a svg{ margin: -3px 0 0 0!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k{ margin: 24px 0 0 0!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k h6{ margin: 0!important }
    .o625uj{ font-size: 17.5px!important }
    .o625uj{ line-height: 27.5px!important }
    .s4096n{ font-size: 13px!important }
    .s4096n{ line-height: 23px!important }
    .b98853 .row .col-md-6{ width: 100%!important }
    .b98853 .row .col-md-6 .u2r3l1 .row .col-md-6{ width: 50%!important }
    .u646ho .row .col-md-2{ width: 25%!important }
    .v3918a{ height: auto!important }
    .wo9836 .container .row .col-md-4{ width: 100%!important }
    .wo9836 .container .row .col-md-8{ width: 100%!important }
    .j526i9 .container .row .col-md-6{ width: 100%!important }
    .n825zt{ height: auto!important }
    .q97n5j{ font-size: 13px!important }
    .t5t52g{ font-size: 18px!important }
    .t5t52g{ line-height: 28px!important }
    .p96532 br{ display: none }
    .p96532{ font-size: 13px!important }
    .k38o9g{ height: 42px!important }
    .k38o9g{ line-height: 42px!important }
    .k38o9g{ border-radius: 42px!important }
}
@media (min-width: 768px) and (max-width: 800px){
    .y1r83c svg{ width: 50px!important }
    .y321bf li a{ font-size: 12px!important }
    .y321bf li:nth-child(6), .w70rv2 li{ display: none }
    .w70rv2 li:nth-child(1){ display: block!important }
    .jd169t .container .row .col-md-3{ width: 15%!important }
    .jd169t .container .row .col-md-2{ width: 6.66666667%!important }
    .jd169t .container .row .col-md-7{ width: 78.33333333%!important }
    .y321bf li a{ padding: 0 13px!important }
    .y321bf li:first-child a{ padding-left: 0!important }
    .y321bf li:last-child a{ padding-right: 0!important }
    .jd169t{ background: rgba( 51, 51, 51, 0.8 )!important }
    .r383mb{ margin: 0!important }
    .k28362{ font-size: 24px!important }
    .k28362{ line-height: 40px!important }
    .k28362, .a4396d{ max-width: 300px!important }
    .a4396d{ font-size: 13px!important }
    .z274br{ height: 44px!important }
    .z274br{ border-radius: 44px!important }
    .z274br{ max-width: 180px!important }
    .z274br{ font-size: 13px!important }
    .c7ar92{ height: 377px!important }
    .t003r7::before{ margin: 5px 0 0 1.58px!important }
    .w181kh::before{ margin: 8px 0 0 1.58px!important }
    .A8vD2r, .jWw0y4, .GYBm67{ height: auto!important }
    .jWw0y4, .GYBm67{ width: 50%!important }
    .jWw0y4, .GYBm67{ padding: 35px 0!important }
    .w4552r .container .row .col-md-4{ width: 100%!important }
    .rSW7Yc{ display: -webkit-box }
    .rSW7Yc{ overflow: hidden }
    .rSW7Yc{ -webkit-box-orient: vertical }
    .rSW7Yc{ -webkit-line-clamp: 2 }
    .w4552r .container .row .col-md-4:first-child .b3d6s6{ height: 150px!important }
    .b3d6s6{ height: auto!important }
    .b3d6s6, .iga296{ margin: 0!important }
    .b3d6s6{ justify-content: center!important }
    .iga296, .h8j670{ height: auto!important }
    .t7i77g br{ display: none }
    .b3d6s6{ align-items: center!important }
    .t7i77g{ width: 100%!important }
    .t7i77g{ text-align: center!important }
    .o10v60{ justify-content: center!important }
    .t7i77g, .h8j670{ margin: 30px 0 0 0!important }
    .i5834n .container .row .col-md-4 .x2g758{ margin: 10px 0 0 0!important }
    .x2g758{ margin: 25px 0 0 0!important }
    .o10v60, .ay75v7, .h64061{ margin: 10px 0 0 0!important }
    .i5834n .container .row .col-md-8, .hX85rr .row .col-md-7{ width: 100%!important }
    .i5834n .container .row .col-md-4, .hX85rr .row .col-md-5{ width: 100%!important }
    .i5834n .container .row .col-md-6{ width: 100%!important }
    .uF4STK, .zX2EQz{ margin: 32px 0 0 0!important }
    .TQQa4G .s66l57 .row .col-md-6{ width: 50%!important }
    .hm2544 .container .row .col-md-4{ width: 100%!important }
    .hm2544 .container .row .col-md-4:nth-child(2) .b87i0d{ margin: 0!important }
    .hm2544 .container .row .col-md-4:last-child .b87i0d{ margin: 24px 0 0 0!important }
    .j64fh9 .p91zc1 .container .row .col-md-2{ width: 20%!important }
    .o7z358{ font-size: 13px!important }
    .o7z358, .d9o0s6 li a, .u2p43p{ overflow: hidden }
    .o7z358, .d9o0s6 li a, .u2p43p{ text-overflow: ellipsis }
    .o7z358, .d9o0s6 li a, .u2p43p{ white-space: nowrap }
    .w70rv2 li a svg{ width: 15px!important }
    .w70rv2 li a svg{ height: 15px!important }
    .uw27j9 .row .col-md-3{ width: 33.3%!important }
    .w70rv2 li a svg{ margin: -3px 0 0 0!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k{ margin: 24px 0 0 0!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k h6{ margin: 0!important }
    .o625uj{ font-size: 17.5px!important }
    .o625uj{ line-height: 27.5px!important }
    .s4096n{ font-size: 13px!important }
    .s4096n{ line-height: 23px!important }
    .b98853 .row .col-md-6{ width: 100%!important }
    .b98853 .row .col-md-6 .u2r3l1 .row .col-md-6{ width: 50%!important }
    .u646ho .row .col-md-2{ width: 25%!important }
    .v3918a{ height: auto!important }
    .wo9836 .container .row .col-md-4{ width: 100%!important }
    .wo9836 .container .row .col-md-8{ width: 100%!important }
    .j526i9 .container .row .col-md-6{ width: 100%!important }
    .n825zt{ height: auto!important }
    .q97n5j{ font-size: 13px!important }
    .t5t52g{ font-size: 18px!important }
    .t5t52g{ line-height: 28px!important }
    .p96532 br{ display: none }
    .p96532{ font-size: 13px!important }
    .k38o9g{ height: 42px!important }
    .k38o9g{ line-height: 42px!important }
    .k38o9g{ border-radius: 42px!important }
    .a4396d{ line-height: 23px!important }
    .a4396d{ overflow: hidden }
    .a4396d{ white-space: nowrap }
    .a4396d{ text-overflow: ellipsis }
    .wo9836 .offset-md-4{ margin-left: 0!important }
    .c2l68g{ height: 50px!important }
    .n3186t .c2l68g{ height: auto!important }
    .n3186t .c2l68g{ margin: 32px 0 0 0!important }
    .q072j5 .row .col-md-4:nth-child(2) .c4eh00{ margin: 12px 0 0 0!important }
    .q072j5 .row .col-md-4:last-child .c4eh00{ margin: 12px 0 0 0!important }
}
@media screen and (max-width: 767px){
    .jd169t{ display: none!important }
    .jq9p54{ display: block!important }
}
@media screen and (min-width:600px) and (max-width:767px){
    .r383mb{ margin: 0!important }
    .v258gv, .w42bk5{ display: none!important }
    .uw27j9 .row .col-md-3{ width: 33.3%!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k{ margin: 24px 0 0 0!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k{ margin: 36px 0 0 0!important }
    .uw27j9 .row .col-md-12 .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k .h45j3c:last-child{ margin: 24px 0 0 0!important }
    .t405qk .row .col-md-6:last-child .u2p43p{ display: none!important }
    .k28362{ font-size: 24px!important }
    .k28362{ line-height: 40px!important }
    .a4396d{ font-size: 13.5px!important }
    .z274br{ height: 44px!important }
    .z274br{ border-radius: 44px!important }
    .z274br{ max-width: 180px!important }
    .z274br{ font-size: 13px!important }
    .a4396d{ margin: 8px 0 0 0!important }
    .z274br{ margin: 10px 0 0 0!important }
    .A8vD2r{ height: auto!important }
    .A8vD2r .jWw0y4{ width: 100%!important }
    .A8vD2r .GYBm67{ width: 100%!important }
    .jWw0y4, .GYBm67{ height: auto!important }
    .jWw0y4, .GYBm67{ padding: 35px 0!important }
    .rSW7Yc{ margin: 8px 0 0 0!important }
    .OO3Kdm{ margin: 10px 0 0 0!important }
    .rSW7Yc{ width: 100%!important }
    .Yy2TKZ{ font-size: 14px!important }
    .h8j670{ height: auto!important }
    .kd539k{ font-size: 14px!important }
    .ay75v7, .h64061, .o7z358{ font-size: 13px!important }
    .Ha7OWF{ margin: 2px 0 0 0!important }
    .W6E3mt{ margin: 10px 0 0 0!important }
    .zX2EQz{ margin: 32px 0 0 0!important }
    .j64fh9 .p91zc1 .container .row .col-md-2{ width: 25%!important }
    .o7z358{ overflow: hidden }
    .o7z358{ white-space: nowrap }
    .o7z358{ text-overflow: ellipsis }
    .o8437e{ font-size: 14px!important }
    .z96m9l{ font-size: 13px!important }
    .z96m9l{ line-height: 23px!important }
    .o8437e, .z96m9l{ text-align: center!important }
    .h0y628{ align-items: center!important }
    .hm2544 .container .row .col-md-4:first-child .b87i0d{ margin-bottom: 8px!important }
    .hm2544 .container .row .col-md-4:nth-child(2) .b87i0d{ margin: 8px 0!important }
    .hm2544 .container .row .col-md-4:nth-child(3) .b87i0d{ margin: 8px 0!important }
    .c2l68g{ height: auto!important }
    .o625uj{ margin: 42px 0 0 0!important }
    .o625uj{ font-size: 17px!important }
    .o625uj{ line-height: 27px!important }
    .o625uj, .s4096n{ text-align: center!important }
    .wo9836 .container .row .col-md-8 .o625uj{ margin: 24px 0 0 0!important }
    .s4096n{ font-size: 13px!important }
    .s4096n{ line-height: 23.5px!important }
    .q072j5 .row .col-md-4 .c4eh00{ margin: 0 0 24px 0!important }
    .q9s876{ margin: 12px 0 0 0!important }
    .q9s876{ width: 100%!important }
    .q9s876{ display: flex }
    .q9s876{ justify-content: center }
    .q9s876{ align-items: center }
    .zX2EQz .row .col-md-6:nth-child(2) .Ecx0eZ .oSb1Ts{ margin: 24px 0 0 0!important }
    .e06m9j .row .col-md-6:nth-child(2) .v64ff5{ margin: 24px 0 0 0!important }
    .u646ho .row .col-md-2{ width: 33.3%!important }
    .v3918a{ height: auto!important }
    .n3186t{ display: flex }
    .n3186t{ flex-direction: column }
    .n3186t{ justify-content: center }
    .n3186t{ align-items: center }
    .wo9836 .container .row .col-md-4:first-child .n3186t{ margin: 24px 0 0 0!important }
    .j65d9q, .q4674j{ font-size: 13px!important }
    .c1349i, .j65d9q, .q4674j, .q97n5j{ text-align: center }
    .v0116i, .s5p5x1, .n825zt{ height: auto!important }
    .n825zt{ align-items: center!important }
    .q97n5j{ font-size: 13px!important }
    .t5t52g{ font-size: 16px!important }
    .t5t52g{ line-height: 26px!important }
    .t5t52g{ text-align: center!important }
    .p96532{ margin: 12px 0 0 0!important }
    .p96532{ font-size: 13px!important }
    .p96532{ text-align: center!important }
    .e1f54e .t4q95f .w298l4 li .col-md-6{ width: 50%!important }
    .a4396d{ padding-left: 0!important }
    .b3d6s6, .iga296{ height: auto!important }
    .o37v2n{ font-size: 13.5px!important }
    .b3d6s6, .o10v60{ justify-content: center!important }
    .b3d6s6, .iga296{ margin: 32px 0 0 0!important }
    .b3d6s6{ text-align: center!important }
    .o10v60{ margin: 12px 0 0 0!important }
    .b3d6s6{ margin: 24px 0 0 0!important }
    .x0i603 li a, .x0i603 li span{ overflow: hidden }
    .x0i603 li a, .x0i603 li span{ white-space: nowrap }
    .x0i603 li a, .x0i603 li span{ text-overflow: ellipsis }
}
@media screen and (min-width:500px) and (max-width:599px){
    .r383mb{ margin: 0!important }
    .v258gv, .w42bk5{ display: none!important }
    .uw27j9 .row .col-md-3{ width: 33.3%!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k{ margin: 24px 0 0 0!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k{ margin: 36px 0 0 0!important }
    .uw27j9 .row .col-md-12 .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k .h45j3c:last-child{ margin: 24px 0 0 0!important }
    .t405qk .row .col-md-6:last-child .u2p43p{ display: none!important }
    .k28362{ font-size: 18px!important }
    .k28362{ line-height: 30px!important }
    .a4396d{ font-size: 13.5px!important }
    .z274br{ height: 44px!important }
    .z274br{ border-radius: 44px!important }
    .z274br{ max-width: 180px!important }
    .z274br{ font-size: 13px!important }
    .a4396d{ margin: 8px 0 0 0!important }
    .z274br{ margin: 10px 0 0 0!important }
    .A8vD2r{ height: auto!important }
    .A8vD2r .jWw0y4{ width: 100%!important }
    .A8vD2r .GYBm67{ width: 100%!important }
    .jWw0y4, .GYBm67{ height: auto!important }
    .jWw0y4, .GYBm67{ padding: 35px 0!important }
    .rSW7Yc{ margin: 8px 0 0 0!important }
    .OO3Kdm{ margin: 10px 0 0 0!important }
    .rSW7Yc{ width: 100%!important }
    .Yy2TKZ{ font-size: 14px!important }
    .h8j670{ height: auto!important }
    .kd539k{ font-size: 14px!important }
    .ay75v7, .h64061, .o7z358{ font-size: 13px!important }
    .Ha7OWF{ margin: 2px 0 0 0!important }
    .W6E3mt{ margin: 10px 0 0 0!important }
    .zX2EQz{ margin: 32px 0 0 0!important }
    .j64fh9 .p91zc1 .container .row .col-md-2{ width: 25%!important }
    .o7z358{ overflow: hidden }
    .o7z358{ white-space: nowrap }
    .o7z358{ text-overflow: ellipsis }
    .o8437e{ font-size: 14px!important }
    .z96m9l{ font-size: 13px!important }
    .z96m9l{ line-height: 23px!important }
    .o8437e, .z96m9l{ text-align: center!important }
    .h0y628{ align-items: center!important }
    .hm2544 .container .row .col-md-4:first-child .b87i0d{ margin-bottom: 8px!important }
    .hm2544 .container .row .col-md-4:nth-child(2) .b87i0d{ margin: 8px 0!important }
    .hm2544 .container .row .col-md-4:nth-child(3) .b87i0d{ margin: 8px 0!important }
    .c2l68g{ height: auto!important }
    .o625uj{ margin: 42px 0 0 0!important }
    .o625uj{ font-size: 17px!important }
    .o625uj{ line-height: 27px!important }
    .o625uj, .s4096n{ text-align: center!important }
    .wo9836 .container .row .col-md-8 .o625uj{ margin: 24px 0 0 0!important }
    .s4096n{ font-size: 13px!important }
    .s4096n{ line-height: 23.5px!important }
    .q072j5 .row .col-md-4 .c4eh00{ margin: 0 0 24px 0!important }
    .q9s876{ margin: 12px 0 0 0!important }
    .q9s876{ width: 100%!important }
    .q9s876{ display: flex }
    .q9s876{ justify-content: center }
    .q9s876{ align-items: center }
    .zX2EQz .row .col-md-6:nth-child(2) .Ecx0eZ .oSb1Ts{ margin: 24px 0 0 0!important }
    .e06m9j .row .col-md-6:nth-child(2) .v64ff5{ margin: 24px 0 0 0!important }
    .u646ho .row .col-md-2{ width: 33.3%!important }
    .v3918a{ height: auto!important }
    .n3186t{ display: flex }
    .n3186t{ flex-direction: column }
    .n3186t{ justify-content: center }
    .n3186t{ align-items: center }
    .wo9836 .container .row .col-md-4:first-child .n3186t{ margin: 24px 0 0 0!important }
    .j65d9q, .q4674j{ font-size: 13px!important }
    .c1349i, .j65d9q, .q4674j, .q97n5j{ text-align: center }
    .v0116i, .s5p5x1, .n825zt{ height: auto!important }
    .n825zt{ align-items: center!important }
    .q97n5j{ font-size: 13px!important }
    .t5t52g{ font-size: 16px!important }
    .t5t52g{ line-height: 26px!important }
    .t5t52g{ text-align: center!important }
    .p96532{ margin: 12px 0 0 0!important }
    .p96532{ font-size: 13px!important }
    .p96532{ text-align: center!important }
    .e1f54e .t4q95f .w298l4 li .col-md-6{ width: 50%!important }
    .a4396d{ padding-left: 0!important }
    .b3d6s6, .iga296{ height: auto!important }
    .o37v2n{ font-size: 13.5px!important }
    .b3d6s6, .o10v60{ justify-content: center!important }
    .b3d6s6{ margin: 24px 0 0 0!important }
    .b3d6s6, .k28362{ text-align: center!important }
    .o10v60{ margin: 12px 0 0 0!important }
    .a4396d, .z274br{ display: none!important }
    .w9u19m{ padding: 0!important }
    .x0i603 li a, .x0i603 li span{ overflow: hidden }
    .x0i603 li a, .x0i603 li span{ white-space: nowrap }
    .x0i603 li a, .x0i603 li span{ text-overflow: ellipsis }
}
@media screen and (min-width:481px) and (max-width:499px){
    .r383mb{ margin: 0!important }
    .v258gv, .w42bk5{ display: none!important }
    .uw27j9 .row .col-md-3{ width: 33.3%!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k{ margin: 24px 0 0 0!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k{ margin: 36px 0 0 0!important }
    .uw27j9 .row .col-md-12 .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k .h45j3c:last-child{ margin: 24px 0 0 0!important }
    .t405qk .row .col-md-6:last-child .u2p43p{ display: none!important }
    .k28362{ font-size: 24px!important }
    .k28362{ line-height: 40px!important }
    .a4396d{ font-size: 13.5px!important }
    .z274br{ height: 44px!important }
    .z274br{ border-radius: 44px!important }
    .z274br{ max-width: 180px!important }
    .z274br{ font-size: 13px!important }
    .a4396d{ margin: 8px 0 0 0!important }
    .z274br{ margin: 10px 0 0 0!important }
    .A8vD2r{ height: auto!important }
    .A8vD2r .jWw0y4{ width: 100%!important }
    .A8vD2r .GYBm67{ width: 100%!important }
    .jWw0y4, .GYBm67{ height: auto!important }
    .jWw0y4, .GYBm67{ padding: 35px 0!important }
    .rSW7Yc{ margin: 8px 0 0 0!important }
    .OO3Kdm{ margin: 10px 0 0 0!important }
    .rSW7Yc{ width: 100%!important }
    .Yy2TKZ{ font-size: 14px!important }
    .h8j670{ height: auto!important }
    .kd539k{ font-size: 14px!important }
    .ay75v7, .h64061, .o7z358{ font-size: 13px!important }
    .Ha7OWF{ margin: 2px 0 0 0!important }
    .W6E3mt{ margin: 10px 0 0 0!important }
    .zX2EQz{ margin: 32px 0 0 0!important }
    .j64fh9 .p91zc1 .container .row .col-md-2{ width: 25%!important }
    .o7z358{ overflow: hidden }
    .o7z358{ white-space: nowrap }
    .o7z358{ text-overflow: ellipsis }
    .o8437e{ font-size: 14px!important }
    .z96m9l{ font-size: 13px!important }
    .z96m9l{ line-height: 23px!important }
    .o8437e, .z96m9l{ text-align: center!important }
    .h0y628{ align-items: center!important }
    .hm2544 .container .row .col-md-4:first-child .b87i0d{ margin-bottom: 8px!important }
    .hm2544 .container .row .col-md-4:nth-child(2) .b87i0d{ margin: 8px 0!important }
    .hm2544 .container .row .col-md-4:nth-child(3) .b87i0d{ margin: 8px 0!important }
    .c2l68g{ height: auto!important }
    .o625uj{ margin: 42px 0 0 0!important }
    .o625uj{ font-size: 17px!important }
    .o625uj{ line-height: 27px!important }
    .o625uj, .s4096n{ text-align: center!important }
    .wo9836 .container .row .col-md-8 .o625uj{ margin: 24px 0 0 0!important }
    .s4096n{ font-size: 13px!important }
    .s4096n{ line-height: 23.5px!important }
    .q072j5 .row .col-md-4 .c4eh00{ margin: 0 0 24px 0!important }
    .q9s876{ margin: 12px 0 0 0!important }
    .q9s876{ width: 100%!important }
    .q9s876{ display: flex }
    .q9s876{ justify-content: center }
    .q9s876{ align-items: center }
    .zX2EQz .row .col-md-6:nth-child(2) .Ecx0eZ .oSb1Ts{ margin: 24px 0 0 0!important }
    .e06m9j .row .col-md-6:nth-child(2) .v64ff5{ margin: 24px 0 0 0!important }
    .u646ho .row .col-md-2{ width: 33.3%!important }
    .v3918a{ height: auto!important }
    .n3186t{ display: flex }
    .n3186t{ flex-direction: column }
    .n3186t{ justify-content: center }
    .n3186t{ align-items: center }
    .wo9836 .container .row .col-md-4:first-child .n3186t{ margin: 24px 0 0 0!important }
    .j65d9q, .q4674j{ font-size: 13px!important }
    .c1349i, .j65d9q, .q4674j, .q97n5j{ text-align: center }
    .v0116i, .s5p5x1, .n825zt{ height: auto!important }
    .n825zt{ align-items: center!important }
    .q97n5j{ font-size: 13px!important }
    .t5t52g{ font-size: 16px!important }
    .t5t52g{ line-height: 26px!important }
    .t5t52g{ text-align: center!important }
    .p96532{ margin: 12px 0 0 0!important }
    .p96532{ font-size: 13px!important }
    .p96532{ text-align: center!important }
    .e1f54e .t4q95f .w298l4 li .col-md-6{ width: 50%!important }
    .a4396d{ padding-left: 0!important }
    .b3d6s6, .iga296{ height: auto!important }
    .o37v2n{ font-size: 13.5px!important }
    .b3d6s6, .o10v60{ justify-content: center!important }
    .b3d6s6{ margin: 24px 0 0 0!important }
    .b3d6s6{ text-align: center!important }
    .o10v60{ margin: 12px 0 0 0!important }
    .x0i603 li a, .x0i603 li span{ overflow: hidden }
    .x0i603 li a, .x0i603 li span{ white-space: nowrap }
    .x0i603 li a, .x0i603 li span{ text-overflow: ellipsis }
}
@media screen and (min-width:321px) and (max-width:480px){
    .r383mb{ margin: 0!important }
    .v258gv, .w42bk5{ display: none!important }
    .uw27j9 .row .col-md-3{ width: 50%!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k{ margin: 24px 0 0 0!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k{ margin: 36px 0 0 0!important }
    .uw27j9 .row .col-md-12 .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k .h45j3c:last-child{ margin: 24px 0 0 0!important }
    .t405qk .row .col-md-6:last-child .u2p43p{ display: none!important }
    .k28362{ font-size: 18px!important }
    .k28362{ line-height: 30px!important }
    .a4396d{ font-size: 13.5px!important }
    .z274br{ height: 40px!important }
    .z274br{ border-radius: 40px!important }
    .z274br{ max-width: 180px!important }
    .z274br{ font-size: 13px!important }
    .a4396d{ margin: 8px 0 0 0!important }
    .z274br{ margin: 12px 0 0 0!important }
    .A8vD2r{ height: auto!important }
    .A8vD2r .jWw0y4{ width: 100%!important }
    .A8vD2r .GYBm67{ width: 100%!important }
    .jWw0y4, .GYBm67{ height: auto!important }
    .jWw0y4, .GYBm67{ padding: 35px 0!important }
    .rSW7Yc{ margin: 8px 0 0 0!important }
    .OO3Kdm{ margin: 10px 0 0 0!important }
    .rSW7Yc{ width: 100%!important }
    .Yy2TKZ{ font-size: 13.5px!important }
    .h8j670{ height: auto!important }
    .kd539k{ font-size: 14px!important }
    .ay75v7, .h64061, .o7z358{ font-size: 13px!important }
    .Ha7OWF{ margin: 2px 0 0 0!important }
    .W6E3mt{ margin: 10px 0 0 0!important }
    .zX2EQz{ margin: 32px 0 0 0!important }
    .j64fh9 .p91zc1 .container .row .col-md-2{ width: 33.3%!important }
    .o7z358, .a4396d, .d9o0s6 li a{ overflow: hidden }
    .o7z358, .a4396d, .d9o0s6 li a{ white-space: nowrap }
    .o7z358, .a4396d, .d9o0s6 li a{ text-overflow: ellipsis }
    .o8437e{ font-size: 14px!important }
    .z96m9l{ font-size: 13px!important }
    .z96m9l{ line-height: 23px!important }
    .o8437e, .z96m9l{ text-align: center!important }
    .h0y628{ align-items: center!important }
    .hm2544 .container .row .col-md-4:first-child .b87i0d{ margin-bottom: 8px!important }
    .hm2544 .container .row .col-md-4:nth-child(2) .b87i0d{ margin: 8px 0!important }
    .hm2544 .container .row .col-md-4:nth-child(3) .b87i0d{ margin: 8px 0!important }
    .c2l68g{ height: auto!important }
    .o625uj{ margin: 42px 0 0 0!important }
    .o625uj{ font-size: 17px!important }
    .o625uj{ line-height: 27px!important }
    .o625uj, .s4096n{ text-align: center!important }
    .wo9836 .container .row .col-md-8 .o625uj{ margin: 24px 0 0 0!important }
    .s4096n{ font-size: 13px!important }
    .s4096n{ line-height: 23.5px!important }
    .q072j5 .row .col-md-4 .c4eh00{ margin: 0 0 24px 0!important }
    .q9s876{ margin: 12px 0 0 0!important }
    .q9s876{ width: 100%!important }
    .q9s876{ display: flex }
    .q9s876{ justify-content: center }
    .q9s876{ align-items: center }
    .zX2EQz .row .col-md-6:nth-child(2) .Ecx0eZ .oSb1Ts{ margin: 24px 0 0 0!important }
    .e06m9j .row .col-md-6 .e5j51g{ margin: 21px 0 0 0!important }
    .e06m9j .row .col-md-6:nth-child(1) .v64ff5{ margin: 0 0 0 0!important }
    .e06m9j .row .col-md-6:nth-child(2) .v64ff5 input{ margin: 21px 0 0 0!important }
    .e437x9, .z6f978{ margin: 21px 0 0 0!important }
    .u646ho .row .col-md-2{ width: 33.3%!important }
    .v3918a{ height: auto!important }
    .n3186t{ display: flex }
    .n3186t{ flex-direction: column }
    .n3186t{ justify-content: center }
    .n3186t{ align-items: center }
    .wo9836 .container .row .col-md-4:first-child .n3186t{ margin: 24px 0 0 0!important }
    .j65d9q, .q4674j{ font-size: 13px!important }
    .c1349i, .j65d9q, .q4674j, .q97n5j{ text-align: center }
    .v0116i, .s5p5x1, .n825zt{ height: auto!important }
    .n825zt{ align-items: center!important }
    .q97n5j{ font-size: 13px!important }
    .t5t52g{ font-size: 16px!important }
    .t5t52g{ line-height: 26px!important }
    .t5t52g{ text-align: center!important }
    .p96532{ margin: 12px 0 0 0!important }
    .p96532{ font-size: 13px!important }
    .p96532{ text-align: center!important }
    .e1f54e .t4q95f .w298l4 li .col-md-6{ width: 50%!important }
    .k28362{ max-width: 360px!important }
    .a4396d{ max-width: 355px!important }
    .k28362{ font-size: 14px!important }
    .k28362{ line-height: 24px!important }
    .a4396d{ font-size: 13px!important }
    .w9u19m{ padding: 0!important }
    .rSW7Yc{ font-size: 12.5px!important }
    .rSW7Yc{ line-height: 22px!important }
    .rSW7Yc{ margin: 5px 0 0 0!important }
    .OO3Kdm{ margin: 8px 0 0 0!important }
    .kd539k{ font-size: 13.5px!important }
    .n5e3z9, .s4lCEP{ align-items: center!important }
    .a4396d{ padding-left: 0!important }
    .k28362, .rSW7Yc, .m036r3{ text-align: center!important }
    .k3u18p{ height: 48px!important }
    .b3d6s6, .iga296{ height: auto!important }
    .o37v2n{ font-size: 13.5px!important }
    .b3d6s6, .o10v60{ justify-content: center!important }
    .iga296, .uF4STK, .x2g758{ margin: 24px 0 0 0!important }
    .b3d6s6{ margin: 18px 0 0 0!important }
    .b3d6s6{ text-align: center!important }
    .o10v60, .ay75v7{ margin: 12px 0 0 0!important }
    .z274br{ display: none!important }
    .a4396d{ display: none!important }
    .rSW7Yc, .t7i77g{ display: -webkit-box }
    .rSW7Yc, .t7i77g{ -webkit-box-orient: vertical }
    .rSW7Yc, .t7i77g{ -webkit-line-clamp: 3 }
    .rSW7Yc, .t7i77g{ overflow: hidden }
    .t7i77g, .u2p43p{ font-size: 13px!important }
    .t7i77g{ line-height: 23px!important }
    .t7i77g br{ display: none!important }
    .k05ss5, .d9o0s6 li a, .b61409{ text-align: center }
    .d9o0s6 li a, .b61409{ font-size: 13px!important }
    .d9o0s6 li a{ line-height: 18px!important }
    .d911nu{ justify-content: center!important }
    .Ha7OWF{ line-height: 23px!important }
    .x0i603 li a, .x0i603 li span, .u2p43p, .k28362{ overflow: hidden }
    .x0i603 li a, .x0i603 li span, .u2p43p, .k28362{ white-space: nowrap }
    .x0i603 li a, .x0i603 li span, .u2p43p, .k28362{ text-overflow: ellipsis }
    .uw27j9 .row .col-md-3:nth-child(1) .m7373k{ margin: 24px 0 0 0 !important }
    .uw27j9 .row .col-md-3:nth-child(2) .m7373k{ margin: 24px 0 0 0 !important }
    .uw27j9 .row .col-md-3:nth-child(3) .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-3:nth-child(3) .m7373k{ margin: 24px 0 0 0 !important }
}
@media screen and (max-width: 320px){
    .r383mb{ margin: 12px 0 0 0!important }
    .v258gv, .w42bk5{ display: none!important }
    .uw27j9 .row .col-md-3{ width: 100%!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k{ margin: 24px 0 0 0!important }
    .uw27j9 .row .col-md-3:nth-child(4) .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k{ margin: 36px 0 0 0!important }
    .uw27j9 .row .col-md-12 .m7373k .k05ss5{ margin: 0!important }
    .uw27j9 .row .col-md-12 .m7373k .h45j3c:last-child{ margin: 24px 0 0 0!important }
    .t405qk .row .col-md-6:last-child .u2p43p{ display: none!important }
    .k28362{ font-size: 18px!important }
    .k28362{ line-height: 30px!important }
    .a4396d{ font-size: 13.5px!important }
    .z274br{ height: 38px!important }
    .z274br{ border-radius: 38px!important }
    .z274br{ max-width: 160px!important }
    .z274br{ font-size: 13px!important }
    .a4396d{ margin: 8px 0 0 0!important }
    .z274br{ margin: 12px 0 0 0!important }
    .A8vD2r{ height: auto!important }
    .A8vD2r .jWw0y4{ width: 100%!important }
    .A8vD2r .GYBm67{ width: 100%!important }
    .jWw0y4, .GYBm67{ height: auto!important }
    .jWw0y4, .GYBm67{ padding: 35px 0!important }
    .rSW7Yc{ margin: 8px 0 0 0!important }
    .OO3Kdm{ margin: 10px 0 0 0!important }
    .rSW7Yc{ width: 100%!important }
    .Yy2TKZ{ font-size: 13px!important }
    .h8j670{ height: auto!important }
    .o7z358{ font-size: 13px!important }
    .ay75v7, .h64061{ font-size: 12.5px!important }
    .ay75v7{ line-height: 22px!important }
    .Ha7OWF{ margin: 2px 0 0 0!important }
    .W6E3mt{ margin: 10px 0 0 0!important }
    .zX2EQz{ margin: 32px 0 0 0!important }
    .j64fh9 .p91zc1 .container .row .col-md-2{ width: 50%!important }
    .o7z358, .d9o0s6 li a, .u2p43p, .Yy2TKZ{ overflow: hidden }
    .o7z358, .d9o0s6 li a, .u2p43p, .Yy2TKZ{ white-space: nowrap }
    .o7z358, .d9o0s6 li a, .u2p43p, .Yy2TKZ{ text-overflow: ellipsis }
    .o8437e{ font-size: 13px!important }
    .z96m9l{ font-size: 13px!important }
    .z96m9l{ line-height: 23px!important }
    .o8437e, .z96m9l, .a4396d{ text-align: center!important }
    .h0y628{ align-items: center!important }
    .hm2544 .container .row .col-md-4:first-child .b87i0d{ margin-bottom: 8px!important }
    .hm2544 .container .row .col-md-4:nth-child(2) .b87i0d{ margin: 8px 0!important }
    .hm2544 .container .row .col-md-4:nth-child(3) .b87i0d{ margin: 8px 0!important }
    .c2l68g{ height: auto!important }
    .o625uj{ margin: 42px 0 0 0!important }
    .o625uj{ font-size: 17px!important }
    .o625uj{ line-height: 27px!important }
    .o625uj, .s4096n{ text-align: center!important }
    .wo9836 .container .row .col-md-8 .o625uj{ margin: 24px 0 0 0!important }
    .s4096n{ font-size: 13px!important }
    .s4096n{ line-height: 23.5px!important }
    .q072j5 .row .col-md-4 .c4eh00{ margin: 0 0 24px 0!important }
    .q9s876{ margin: 12px 0 0 0!important }
    .q9s876{ width: 100%!important }
    .q9s876{ display: flex }
    .q9s876{ justify-content: center }
    .q9s876{ align-items: center }
    .zX2EQz .row .col-md-6:nth-child(2) .Ecx0eZ .oSb1Ts{ margin: 24px 0 0 0!important }
    .e06m9j .row .col-md-6 .e5j51g{ margin: 21px 0 0 0!important }
    .e06m9j .row .col-md-6:nth-child(1) .v64ff5{ margin: 0 0 0 0!important }
    .e06m9j .row .col-md-6:nth-child(2) .v64ff5 input{ margin: 21px 0 0 0!important }
    .e437x9, .z6f978{ margin: 21px 0 0 0!important }
    .u646ho .row .col-md-2{ width: 33.3%!important }
    .v3918a{ height: auto!important }
    .n3186t{ display: flex }
    .n3186t{ flex-direction: column }
    .n3186t{ justify-content: center }
    .n3186t{ align-items: center }
    .wo9836 .container .row .col-md-4:first-child .n3186t{ margin: 0!important }
    .j65d9q, .q4674j{ font-size: 13px!important }
    .c1349i, .j65d9q, .q4674j, .q97n5j{ text-align: center }
    .v0116i, .s5p5x1, .n825zt{ height: auto!important }
    .n825zt{ align-items: center!important }
    .q97n5j{ font-size: 13px!important }
    .t5t52g{ font-size: 13px!important }
    .t5t52g{ line-height: 23px!important }
    .t5t52g{ text-align: center!important }
    .p96532{ margin: 8px 0 0 0!important }
    .p96532{ font-size: 13px!important }
    .p96532{ text-align: center!important }
    .e1f54e .t4q95f .w298l4 li .col-md-6{ width: 50%!important }
    .k28362{ max-width: 260px!important }
    .a4396d{ max-width: 100%!important }
    .k28362{ font-size: 18px!important }
    .k28362{ line-height: 30px!important }
    .a4396d{ font-size: 13px!important }
    .w9u19m{ padding: 0!important }
    .rSW7Yc, .OO3Kdm{ font-size: 12px!important }
    .rSW7Yc, .z96m9l, .OO3Kdm{ line-height: 21px!important }
    .rSW7Yc{ margin: 5px 0 0 0!important }
    .OO3Kdm{ margin: 8px 0 0 0!important }
    .kd539k{ font-size: 13px!important }
    .n5e3z9, .s4lCEP, .k05ss5{ align-items: center!important }
    .a4396d{ padding-left: 0!important }
    .k28362, .rSW7Yc, .m036r3{ text-align: center!important }
    .k3u18p{ height: 48px!important }
    .d9o0s6 li a, .z96m9l{ font-size: 12px!important }
    .d9o0s6 li a, .k05ss5{ text-align: center }
    .m7373k{ margin: 18px 0 0 0!important }
    .b61409, .c83375, .u2p43p, .Ha7OWF{ font-size: 12px!important }
    .b61409{ text-align: center }
    .d911nu{ justify-content: center!important }
    .b61409{ line-height: 22px!important }
    .b87i0d{ padding-left: 12px!important }
    .b87i0d{ padding-right: 12px!important }
    .q656t5{ height: 30px!important }
    .q656t5{ max-width: 30px!important }
    .q656t5 svg{ width: 12px!important }
    .q656t5 svg{ height: 12px!important }
    .w9u19m{ position: relative!important }
    .mau703::before, .r383mb{ height: auto!important }
    .w9u19m{ background: #FFFFFF }
    .w9u19m{ padding: 35px 0 35px 0!important }
    .z274br{ background: #333333!important }
    .z274br{ border: 2px solid #333333!important }
    .z274br{ color: #FFFFFF!important }
    .k28362{ font-size: 14px!important }
    .k28362, .a4396d{ color: #333333!important }
    .k28362{ line-height: 25px!important }
    .a4396d{ font-size: 12px!important }
    .a4396d{ line-height: 21px!important }
    .z274br{ font-size: 12px!important }
    .b3d6s6, .iga296{ height: auto!important }
    .o37v2n{ font-size: 13px!important }
    .b3d6s6, .o10v60{ justify-content: center!important }
    .b3d6s6, .iga296, .uF4STK{ margin: 24px 0 0 0!important }
    .t7i77g{ font-size: 12.5px!important }
    .t7i77g{ line-height: 22px!important }
    .t7i77g{ width: 100%!important }
    .t7i77g{ text-align: center!important }
    .t7i77g{ display: none!important }
    .o10v60{ margin: 0!important }
    .a4396d{ width: 100%!important }
    .rSW7Yc{ display: -webkit-box }
    .rSW7Yc{ -webkit-box-orient: vertical }
    .rSW7Yc{ overflow: hidden }
    .rSW7Yc{ -webkit-line-clamp: 2 }
    .e66x2v{ display: none!important }
    .x0i603 li a, .x0i603 li span{ overflow: hidden }
    .x0i603 li a, .x0i603 li span{ white-space: nowrap }
    .x0i603 li a, .x0i603 li span{ text-overflow: ellipsis }
    .k38o9g{ display: none!important }
    .j526i9{ padding: 24px 0 32px 0!important }
    .q97n5j, .p96532{ font-size: 12px!important }
    .q97n5j, .t5t52g{ margin: 12px 0 0 0!important }
    .p96532{ line-height: 21px!important }
}